html {
  scroll-behavior: smooth;
}

.App {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #fff;
  background-attachment: fixed;
  font-weight: 700;
  position: relative;
}

.copyright {
  font-weight: 500;
  font-size: 16px;
  line-height: 85%;
  text-align: center;
  color: #D6CEDB;
}

body {
  background: linear-gradient(90deg, #420D55 -8.01%, #2A0A47 113.26%), #2A0A47;
}

body::-webkit-scrollbar {
  display: none;
}

.disable-scroll {
  height: 100%;
  overflow: hidden;
}

p {
  font-family: "Montserrat", sans-serif !important;
}

.hidden {
  overflow: hidden;
}

.category-card {
  position: absolute;
  background: linear-gradient(97.44deg, #FE6E4E -31.54%, #FE4E4E 124.51%), #EDEDED;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.5);
  border-radius: 2px !important;
  font-size: 10px;
  font-weight: 700;
  line-height: 11px;
  left: 23px;
  top: 121px;
  color: #FFFFFF !important;
}

.main-category-card {
  position: absolute;
  background: linear-gradient(97.44deg, #FE6E4E -31.54%, #FE4E4E 124.51%), #EDEDED;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  font-size: 10px !important;
  font-weight: 700!important;
  line-height: 11px !important;
  color: #FFFFFF !important;
  top: 110px;
  left: 15px;
}

div.first ~ div.fourth {
  display: none;
}
div.first:hover ~ div.fourth {
  display: block;
}

.back-card-game {
  position: absolute;
  background: #4D286D;
  box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  width: 228px;
  height: 82px;
  top: 66px;
}

.main-category-views {
  position: absolute;
  width: 35px;
  height: 14px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #28073E;
  top: 6px;
  left: 180px;
  text-align: right;
  padding-right: 2px;
  padding-top: 1px;
}

.icon-views {
  position: absolute;
  top: 4px;
  right: 22px;
}

.card-views {
  position: absolute;
  width: 35px;
  height: 14px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #28073E;
  left: 236px;
  top: 18px;
  text-align: right;
  padding-right: 2px;
  padding-top: 1px;
}

.knopka {
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 70px;
  background: linear-gradient(97.44deg, rgba(254, 110, 78, 0.9) -31.54%, rgba(254, 78, 78, 0.9) 124.51%);
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  right: 10px;
  top: 20%;
  border: 0;
}

.knopkaright {
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 70px;
  left: 970px;
  top: 30%;

  background: linear-gradient(97.44deg, rgba(254, 110, 78, 0.9) -31.54%, rgba(254, 78, 78, 0.9) 124.51%);
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  border: 0;
}

.admin-modal {
  width: 28px;
  height: 28px;
  background: linear-gradient(96.58deg, #FE6E4E -32.76%, #FE4E4E 126.16%);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  border: 0;
  margin-left: 15px;
  position: relative;
}

.knopkagame {
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 70px;
  background: linear-gradient(97.44deg, rgba(254, 110, 78, 0.9) -31.54%, rgba(254, 78, 78, 0.9) 124.51%);
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  right: 15px;
  top: 30%;
  border: 0;
}

.knopkagameright {
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 70px;
  left: 980px;
  top: 20%;
  background: linear-gradient(97.44deg, rgba(254, 110, 78, 0.9) -31.54%, rgba(254, 78, 78, 0.9) 124.51%);
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  border: 0;
}
table {
  color: #cf3f3f;
}

.table-head th {
  color: #FFFFFF !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 85% !important;
  border-bottom: 1px solid #28073E !important;
}

.table-head-body th {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 85% !important;
  color: #FFFFFF !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid #28073E !important;
}

.table-deep-body td {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 85% !important;
  color: #FFFFFF !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid #28073E !important;
}

.icon-table-button {
  color: #fff !important;
}

.table-deep-body div {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 85% !important;
  color: #FFFFFF !important;
}

tr {
  border-bottom: 1px solid #28073E !important;
}





.commentary {
  padding-left: 100px;
  overflow: auto;
  height: 250px;
  margin-bottom: 30px;
}



.commentary::-webkit-scrollbar {
  width: 4px;               /* ширина всей полосы прокрутки */
}

.commentary::-webkit-scrollbar-track {
  background: #28073E;        /* цвет зоны отслеживания */
  width: 8px;
}

.commentary::-webkit-scrollbar-thumb {
  background: linear-gradient(97.44deg, #FE6E4E -31.54%, #FE4E4E 124.51%), #C4C4C4;
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
}

.gl {
  background: url("../assets/gl.png");
  background-attachment: fixed;
  background-size: cover;
  min-height: 1000px;

}

.auth {
  background: url("../assets/regbg.png");
  background-attachment: fixed;
  background-size: cover;
  height: 1000px;
}

/* Basic classes */

h1 {
  font-size: 40px;
  color: white;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  color: white;
  font-weight: 700;
}

h3 {
  font-size: 16px;
  font-weight: 500;

}
h4 {
  font-size: 18px;
  color: white;
  font-weight: 700;
  line-height: 85%;
}


h5 {
  font-size: 12px;
  font-weight: 500;
}

h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 85%;
  color: #FFFFFF;
}

h6 {
  font-weight: 500;
  font-size: 10px;
  line-height: 110%;
  color: #FFFFFF;
}

.game-card-text {
  padding-top: 10px;
  padding-left: 10px;
}

.no-comment {
  padding-top: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.input-modal {
  padding-left: 40px !important;
  width: 380px;
  height: 45px;
  font-weight: 700;
  font-size: 14px;
  line-height: 85%;
  color: #FFFFFF;
}



.input-modal:disabled {
  background: #49155E;
  border-radius: 5px;
  border: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 85%;
  color: #735686;
}

.input-modal::placeholder {
  font-weight: 700;
  font-size: 14px;
  line-height: 85%;
  color: #735686;
}

.custom-chip {
  background: #735686 !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25) !important;
  border-radius: 3px !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 110% !important;
  color: #FFFFFF !important;
  margin-right: 10px !important;
}

.color-chip {
  background: linear-gradient(96.58deg, #FE6E4E -32.76%, #FE4E4E 126.16%)!important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25) !important;
  border-radius: 2px !important;
}
.input-select {
  padding-left: 10px !important;
}

.img-show-password {
  position: absolute;
  left: 457px;
  top: 210px;
}
.description {
  padding-top: 3px;
  padding-left: 10px;
  width: 250px;
}

.profile-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid #000;
  padding: 4px;
}
a {
  color: #fe6e4e;
  text-decoration: none;
}

a:hover {
  color: #cf593f;
}

.img-input {
  height: 26px;
  width: 26px;
  position: absolute;
  margin-top: 18px;
  padding-left: 15px;
}

.img-input-mail {
  position: absolute;
  margin-top: 146px;
  padding-left: 15px;
}

.img-input-password {
  position: absolute;
  margin-top: 210px;
  padding-left: 15px;
}

.custom-slider {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 970px;
  height: 220px;
  transition: 1s;
}

.img-input-group {
  position: absolute;
  margin-top: 82px;
  padding-left: 15px;
}

.img-input-mail-reg {
  position: absolute;
  margin-top: 18px;
  padding-left: 15px;
}

.img-input-password-reg {
  position: absolute;
  top: 18px;
  left: 16px;
}



.error{
  position: absolute;
  right: 318px;
  bottom: 30px;
  z-index: 1;
}

.form {
  position: relative;
}

.i {
  position: absolute;
  margin-top: 25px;
  padding-left: 15px
}

.validation-name {
  position: absolute;
  width: 266px;
  height: 77px;
  background: #49155E;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  left: 550px;
  top: 2px;
}
.validation-email {
  position: absolute;
  width: 266px;
  height: 77px;
  background: #49155E;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  left: 550px;
  top: 118px;
}
.validation-group {
  position: absolute;
  width: 266px;
  height: 77px;
  background: #49155E;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  left: 550px;
  top: 200px;
}

.validation-group-auth {
  position: absolute;
  width: 266px;
  height: 77px;
  background: #49155E;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  top: -82px;
  left: 539px;
}

.error-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 85%;
  padding-top: 10px;
  padding-left: 10px;
  background: linear-gradient(132.91deg, #FF5F54 -157.2%, #E80000 194.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.error-discryption {
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  color: #FFFFFF;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 10px;
  padding-top: 5px;
}

.search-icon {
  position: absolute;
  padding-left: 159px;
  padding-top: 5px;
}

.arrow-category {
  position: absolute;
  padding-left: 150px;
  margin-top: 41px;
}

.arrow-game {
  position: absolute;
  padding-left: 251px;
  margin-top: 50px;
}

.arrow-search {
  position: absolute;
  padding-right: 130px;
  padding-top: 63px;
  right: 0px;
}

.show-pass {
  position: absolute;
  padding-top: 280px;
  padding-left: 368px;
}

.btn-profile {
  width: 160px;
  height: 26px;
  background: #330B4F;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  margin-top: 65px;
  font-size: 14px;
  line-height: 85%;
  text-align: center;
  color: #FFFFFF;
  font-weight: 500;
}

.btn-category {
  width: 206px;
  font-size: 14px;
  height: 26px;
  margin-top: 63px;
  background: #330B4F;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 13%);
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
}

.disable-button {
  opacity: 0.3
}

.btn-allcategory {
  display: inline-block !important;
  width: 206px;
  font-size: 14px;
  height: 26px;
  background: #330B4F;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 13%);
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
}


/* Bootstrap classes */

.card {
  background: #4E0F67;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}

.btn {
  font-size: 16px;
  color: #fff;
  padding: 0.5rem 2.5rem;
  border-radius: 6px;
}

.btn-nav {
  color: #fff;
  background: transparent;
  border: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 85%;
}

.navbar-icons {
  display: flex;
  align-items: flex-end;
}

.navbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-icon {
  margin-bottom: 5px;
}

.navbar-item {
  margin-right: 50px;
}

.navbar-item:last-child {
  height: 55px;
  justify-content: space-between;
  margin-right: 0;
}

.auth-header {
  margin-top: 75px;
  margin-bottom: 0;
}

.auth-subheader {
  margin-bottom: 25px;
}

.btn-nav:hover {
  color: #fe6e4e;
}

.btn-primary {
  background: linear-gradient(97.44deg, #fe6e4e -31.54%, #fe4e4e 124.51%);
  border: none;
  font-weight: 700;
}

.btn-primary:hover {
  background: linear-gradient(97.44deg, #cf593f -31.54%, #cf3f3f 124.51%);
}

.btn-secondary {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
  width: 206px;
  height: 40px;
  font-weight: 700;
}

.btn-secondary:active {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
  width: 206px;
  height: 40px;
}

.btn-secondary:focus {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
  width: 206px;
  height: 40px;
}

.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  border-color: #fe6e4e;
}



.btn-profile:active {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
}

.btn-profile:focus {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
}

.btn-profile:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  border-color: #fe6e4e;
}





.btn-allcategory:active {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
  width: 206px;
  height: 40px;
}

.btn-allcategory:focus {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
  width: 206px;
  height: 40px;
}

.btn-allcategory:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  border-color: #fe6e4e;
}





.btn-category:active {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
}

.btn-category:focus {
  background-color: transparent;
  border-width: 2.5px;
  border-color: #fff;
  border-radius: 6px;
  color: white;
}

.btn-category:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  border-color: #fe6e4e;
}


.form-control:focus {
  color: #fff;
  background: #1E072D;
  box-shadow: inset 0px 4px 18px rgba(0, 0, 0, 0.15);
  border: 0;
}

.arrow-scroll {
  display: block;
  margin: 0 auto;
}

.open-admin-modal {
    position: absolute;
  left: 4px;
  top: 4px;
}

.form-control {
  font-family: "Montserrat", sans-serif !important;
  color: #fff;
  padding: 0 0.75rem;
  line-height: 50px;
  margin: 8px 0;
  font-size: 16px;
  line-height: 48px;
  border: 0;
  padding-left: 50px;
  background: #28073E;
  box-shadow: inset 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 700;
}

.input-modal {
  padding-left: 10px !important;
  box-sizing: border-box;
}

.input-modal-category {
  padding-left: 40px !important;
}


.input-select-category {
  width: 150px;
  height: 40px;
  gap: 10px;
  border: 0;
  background: #4D286D;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 85%;
  color: #FFFFFF;
  margin-left: 10px;
}

.select-disabled {
  font-weight: 700;
  font-size: 14px;
  line-height: 85%;
  color: #FFFFFF;
  opacity: 0.75;
}

.select-category {
  background: #330B4F;
  font-weight: 500;
  font-size: 14px;
  line-height: 85%;
  color: #FFFFFF;
  opacity: 0.75;
}

.select-category:focus {
  background: #cf3f3f;
}

.help-function {
  position: absolute;
  width: 400px;
  height: 85px;
  left:1050px;
  background: #49155E;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  padding-top: 15px;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::placeholder {
  color: #fff;
  opacity: 0.5;
  line-height: 28px;
}

.navbar {
  width: 100%;
  margin-top: 5px;
}

/*.container:nth-child(2) {*/
/*  height: 100vh;*/
/*}*/

/* Custom classes */

.text-table {
  color: #fe6e4e;
}

.hint {
  font-size: 14px;
  position: absolute;
  left: 370px;
  bottom: 20px;
}

.navbar-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 85%;
  background: linear-gradient(96.58deg, #FE6E4E -32.76%, #FE4E4E 126.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  padding-top: 10px;
  padding-bottom: 6px;
}

.navbar-group {
  font-weight: 500;
  font-size: 18px;
  line-height: 85%;
  color: #FFFFFF;
  text-align: end;
}

.color-text {
  color: #FE6E4E;
}
.type-bar {
  background-color: transparent;
  border-color: #fe6e4e;
  color: white;
  top: 15px;
}

.tag {
  display: inline-block;
  padding: 0.15rem 0.5rem;
  margin-right: 10px;
  border: 1.5px solid #fff;
  border-radius: 4px;
  font-size: 12px;
}

.tag:last-child {
  margin-right: 0;
}


.btn-lg {
  font-size: 24px;
}

.card-lg {
  padding: 50px 150px;
}

.input-find::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.input-find::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.input-find:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.input-find:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.input-find:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.input-find:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.input-find:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.input-find:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}



.form-control::-webkit-input-placeholder {
  transition: opacity 0.3s ease;
}
.form-control::-moz-placeholder {
  transition: opacity 0.3s ease;
}
.form-control:-moz-placeholder {
  transition: opacity 0.3s ease;
}
.form-control:-ms-input-placeholder {
  transition: opacity 0.3s ease;
}
.form-control:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.form-control:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.form-control:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.form-control:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}



.input-modal::-webkit-input-placeholder {
  transition: opacity 0.3s ease;
}
.input-modal::-moz-placeholder {
  transition: opacity 0.3s ease;
}
.input-modal:-moz-placeholder {
  transition: opacity 0.3s ease;
}
.input-modal:-ms-input-placeholder {
  transition: opacity 0.3s ease;
}
.input-modal:focus::-webkit-input-placeholder {
  transition: opacity 0.3s ease;
}
.input-modal:focus::-moz-placeholder {
  transition: opacity 0.3s ease;
}
.input-modal:focus:-moz-placeholder {
  transition: opacity 0.3s ease;
}
.input-modal:focus:-ms-input-placeholder {
  transition: opacity 0.3s ease;
}

.input-find {
  border-color: #28073E;
  padding-left: 40px;
  width: 670px;
  height: 45px;
  border-radius: 8px;
  margin: 0 auto;
  font-weight: 700;
}

.input-game {
  margin: 0 !important;
  width: 970px !important;
}

.input-category {
  width: 100% !important;
  margin-top: 20px;
}

.input-com {
  width: 485px;
  height: 96px;
}

.views {
  position: absolute;
  left: 84%;
}

.navbar-icon {
  position: relative;
  cursor: pointer;
}

.navbar-icon-abs {
  position: relative;
  left: 78px;
}

.navbar-icon-category {
  position: relative;
  left: 87px;
}

.navbar-icon-game {
  position: relative;
  left: 81px;
  padding-top: 15px;
}



.input-find::placeholder {
  color: #fff;
  opacity: 0.5;
}

.profile .card {
  padding: 30px 50px;
}

.custom-label {
  opacity: 0.7;
}

.text-primary {
  color: #fe6e4e !important;
}

.select {
  margin-left: 10px;
  width: 200px;
  position: relative;
}

.select__header {
  cursor: pointer;
  height: 44px;
  padding: 13px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  text-align: center;
  background-color: #4D286D;
  border-radius: 5px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
}

.select__current {
  margin-right: 25px;
  font-size: 16px;
}

.select__body {
  display: none;
  width: 315px;
  height: 220px;
  position: absolute;
  right: 0;
  top: 49px;
  z-index: 100;
  background-color: #49155E;
  border-radius: 5px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
}

.select__body::-webkit-scrollbar {
  width: 3px;
  background: #28073E;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.15);
}

.select__body::-webkit-scrollbar-thumb {
  width: 5px;
  height: 106px;
  background: #735686;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.17);
  border-radius: 2px;
}

.select__item {
  cursor: pointer;
  padding: 5px 20px;
  color: #D1C4D7;
  font-size: 14px;
  font-weight: 500;
}

.select__item:first-child {
  padding-top: 10px;
}

.select__item:last-child {
  padding-bottom: 10px;
}

.select__item:hover {
  color: #fff;
  background: linear-gradient(84.79deg, #420D55 -16.43%, #2A0A47 118.26%);
}

.select__item:first-child:hover {
  border-radius: 5px 5px 0 0;
}

.select__item:last-child:hover {
  border-radius: 0 0 5px 5px;
}

.select.is-active .select__body {
  display: block;
}

.select.rotate-img .select__icon {
  transform: rotate(180deg);
}

.css-1ex1afd-MuiTableCell-root {
  border-bottom: 1px solid #28073E !important;

}

.css-rqglhn-MuiTable-root {
  border-collapse: unset !important;
}

.MuiFilledInput-root {
  padding: 12.5px 44px !important;
  background-color: #28073E !important;
  border-radius: 5px !important;
  box-shadow: inset 0px 0px 18px rgba(0, 0, 0, 0.15) !important;

  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #ffffff !important;
}

.MuiFilledInput-root:focus-within {
  background-color: #1E072D !important;
}

.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input::-webkit-input-placeholder {
  opacity: 1 !important;
  color: #735686 !important;
}

.MuiFilledInput-input {
  padding: 0 !important;
}

.MuiAutocomplete-endAdornment {
  display: none !important;
}

.css-sl80c4-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: none !important;
}

.css-sl80c4-MuiInputBase-root-MuiFilledInput-root:after {
  display: none !important;
}

.w-275 {
  width: 275px;
  margin: 0 auto;
}

.MuiBox-root {
  padding: 15px !important;
}

.mui-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mui-form > button {
  width: 175px;
  margin-top: 7px;
}

.MuiBox-root.css-mh8hpj {
  padding: 35px 75px !important;
}